import * as Models from '../models/models';
import { HttpService } from './HttpService';

class SeasonService {

    addSeason = (): Promise<Models.ISeasonSummary> => {
        return HttpService.post<Models.ISeasonSummary>(`/api/admin/season`)
            .then(result => result.data!).catch(error => Promise.reject(error));
    }

    updateSeason = (season: Models.ISeasonSummary | undefined, games: Models.IGameWithCourseSummaryAdmin[]) => {
        if (season) {
            const newGames = [];
            for(let x = 0; x < games.length; x++) {
                newGames.push({...games[x], date: games[x].date?.toISOString()});
            }
            return HttpService.put<any>(`/api/admin/season/${season.id}`, { hotelAddress: season.hotelAddress, games: newGames })
                .then(result => result).catch(error => Promise.reject(error));
        }
    }

    getGames = (seasonId?: number): Promise<Models.ISeasonGames> => {
        return HttpService.get<Models.ISeasonGames>(`/api/Seasons/Games/${seasonId ? seasonId.toString() : ""}`)
            .then(result => result).catch(error => Promise.reject(error));
    }

    getSeasonScoringByYear = (year: number): Promise<Models.ISeasonScoringDetails> => {
        return HttpService.get<Models.ISeasonScoringDetails>(`/api/Seasons/details/year/${year}`)
            .then(result => result).catch(error => Promise.reject(error));;
    }

    getSeasonScoringById = (id: number): Promise<Models.ISeasonScoringDetails> => {
        return HttpService.get<Models.ISeasonScoringDetails>(`/api/Seasons/details/${id}`)
            .then(result => result).catch(error => Promise.reject(error));;
    }

    getSeasonSummaries = (): Promise<Models.ISeasonSummary[]> => {
        return fetch('/api/Seasons/Summary')
            .then(response => response.json() as Promise<Models.ISeasonSummary[]>);
    }

    getGroups = (year: number): Promise<Models.SeasonGroups> => {
        return HttpService.get<Models.SeasonGroups>(`/api/Seasons/Groups/${year}`)
            .then(result => result).catch(error => Promise.reject(error));
    }

    createGroups = (seasonId: number): Promise<Models.SeasonGroups> => {
        return HttpService.post<Models.SeasonGroups>(`/api/admin/season//${seasonId}/groups`)
            .then(result => result.data!).catch(error => Promise.reject(error));
    }

    getRoster = (id: number): Promise<Models.RosterResponse> => {
        return HttpService.get<Models.RosterResponse>(`/api/admin/season/${id}/roster`)
        .then(result => result).catch(error => Promise.reject(error));
    }

    updateRoster = (id: number, roster: number[]): Promise<Models.RosterResponse> => {
        return HttpService.post<Models.RosterResponse>(`/api/admin/season/${id}/roster`, {turtleIds: roster})
        .then(result => result.data!).catch(error => Promise.reject(error));
    }
    getAdminGames = (seasonId: number): Promise<Models.IGameWithCourseSummaryAdmin[]> => {
        return HttpService.get<Models.IGameWithCourseSummaryAdmin[]>(`/api/admin/season/${seasonId}/games`)
            .then(result => result).catch(error => Promise.reject(error));
    }

}

export default new SeasonService();