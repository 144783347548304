import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import * as Models from '../models/models'
import { HttpService } from './HttpService';

class TrophyService {

    getAllWithCurrentWinners = (): Promise<Models.ITrophyAward[]> => {
        return HttpService.get<Models.ITrophyAward[]>(`/api/trophies/current/winners`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    getAllWithCurrentWinnersForSeason = (seasonId: number): Promise<Models.ITrophyAward[]> => {
        return HttpService.get<Models.ITrophyAward[]>(`/api/trophies/season/${seasonId}`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    getHistory = (id: number): Promise<Models.ITrophyAward[]> => {
        return HttpService.get<Models.ITrophyAward[]>(`/api/trophies/history/${id}`)
            .then(response => response).catch(error => Promise.reject(error));
    }

    calculateWinners = async (seasonId: number): Promise<Models.ITrophyAward[] | undefined> => {
        var response = await HttpService.post<Models.ITrophyAward[]>(`/api/admin/season/${seasonId}/trophies`);
        if(!response.isSuccess)
            throw response;
        return response.data;
    }
}

export default new TrophyService();