import "../css/ScoreDetailsPage.scss";
import { Link, useParams } from "react-router-dom";

import * as Models from "../models/models";
import { RoutingService } from "../services/RoutingService";
import ScoreService from "../services/ScoreService";
import { AppLoading } from "../components/common/AppLoading";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Divider, BottomNavigation, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";

const dividerStyle: any = {
  marginTop: 5,
  marginBottom: 5,
};

export const ScoreDetailsPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [score, setScore] = useState<Models.ITurtleScoreWithHoleDetails>();

  let { id } = useParams();

  useEffect(() => {
    document.title = `Score Details`;
    if (!id) {
      return;
    }
    ScoreService.get(+id)
      .then((score) => {
        setIsLoading(false);
        setScore(score);
      })
      .catch((error) => console.error(error));
  }, [id]);

  const formatDate = (date: Date): string => {
    var month = "";
    switch (date.getMonth()) {
      case 0:
        month = "January";
        break;
      case 1:
        month = "February";
        break;
      case 2:
        month = "March";
        break;
      case 3:
        month = "April";
        break;
      case 4:
        month = "May";
        break;
      case 5:
        month = "June";
        break;
      case 6:
        month = "July";
        break;
      case 7:
        month = "August";
        break;
      case 8:
        month = "September";
        break;
      case 9:
        month = "October";
        break;
      case 10:
        month = "November";
        break;
      case 11:
        month = "December";
        break;
    }
    var day = "";
    switch (date.getDay()) {
      case 0:
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        break;
      case 2:
        day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
        break;
    }
    return `${day} ${month} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const renderHeader = (score: Models.ITurtleScoreWithHoleDetails) => {
    return (
      <div className="header">
        <div className="header--summary">
          <div className="header--main">
            <div className="name">
              <Link to={RoutingService.turtle(score.turtle)}>{score.turtle.fullName}</Link>
            </div>
            <div>
              <Link to={RoutingService.course(score.score.game.tee.course)}>{score.score.game.tee.course.name}</Link>
              <span> in </span>
              <Link to={RoutingService.seasonDetails(score.score.game.season)}>{score.score.game.season.year}</Link>
            </div>
          </div>
          <div className="header--tees">
            <div>{score.score.game.tee.name} tees.</div>
            <div>Rating: {score.score.game.tee.rating}</div>
            <div>Slope: {score.score.game.tee.slope}</div>
            <div>Yards: {score.score.game.tee.yardage}</div>
          </div>
          <div className="header--date">{score.score.game.date && formatDate(score.score.game.date!)}</div>
          <div className="header-groups">
            {/* {score.s} */}
          </div>
        </div>
        <div className="header--score">
          {!score.score.played && <div className="header--notPlayed">Turtle Did Not Play This Game</div>}
          <div className="header--gross-score">
            <span> {score.score.grossScore}</span>
          </div>
          <div className="header--net-score">Net: {score.score.netScore}</div>
          {score.score.handicap !== 0 && <div className="header--handicap">Hdcp: {score.score.handicap}</div>}
        </div>
      </div>
    );
  };

  const renderSummary = (score: Models.ITurtleScoreWithHoleDetails) => {
    return (
      <div className="summary">
        {/*<div className="summary--scores">
                <div>
                    <div className="summary--scores--label">Gross</div>
                    <div className="summary--scores--data">{score.score.grossScore}</div>
                </div>
                <div>
                    <div className="summary--scores--label">Net</div>
                    <div className="summary--scores--data">{score.score.netScore}</div>
                </div>
                <div>
                    <div className="summary--scores--label">Handicap</div>
                    <div className="summary--scores--data">{score.score.handicap}</div>
                </div>
            </div>*/}
        <div className="summary--holes">
          {score.score.holeInOnes > 0 && (
            <div>
              <div className="summary--holes--label">Hole-In-Ones</div>
              <div className="summary--holes--data">{score.score.holeInOnes}</div>
            </div>
          )}
          {score.score.albatrosses > 0 && (
            <div>
              <div className="summary--holes--label">Albatrosses</div>
              <div className="summary--holes--data">{score.score.albatrosses}</div>
            </div>
          )}
          <div>
            <div className="summary--holes--label">Eagles</div>
            <div className="summary--holes--data">{score.score.eagles}</div>
          </div>
          <div>
            <div className="summary--holes--label">Birdies</div>
            <div className="summary--holes--data">{score.score.birdies}</div>
          </div>
          <div>
            <div className="summary--holes--label">Pars</div>
            <div className="summary--holes--data">{score.score.pars}</div>
          </div>
        </div>
        <div className="summary--holes">
          <div>
            <div className="summary--holes--label">Bogeys</div>
            <div className="summary--holes--data">{score.score.bogeys}</div>
          </div>
          <div>
            <div className="summary--holes--label">Double Bogeys</div>
            <div className="summary--holes--data">{score.score.doubleBogeys}</div>
          </div>
          <div>
            <div className="summary--holes--label">Double Pars</div>
            <div className="summary--holes--data">{score.score.doublePars}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderFiR = (holeScore: Models.IHoleScore) => {
    return holeScore.hole.par === 3 ? <span /> : <Checkbox className="fir" checked={holeScore.fairwayInRegulation} readOnly={true} disabled={true} />;
  };

  const renderGiR = (holeScore: Models.IHoleScore) => {
    return <Checkbox className="gir" checked={holeScore.greenInRegulation} readOnly={true} disabled={true} />;
  };

  const renderGrossScore = (holeScore: Models.IHoleScore) => {
    let className = "";
    switch (holeScore.grossScore - holeScore.hole.par) {
      case -2:
        className = "score-marker eagle";
        break;
      case -1:
        className = "score-marker birdie";
        break;
      case 0:
        className = "score-marker par";
        break;
      case 1:
        className = "score-marker bogey";
        break;
      case 2:
        className = "score-marker double-bogey";
        break;
      default:
        className = "score-marker";
    }
    if (holeScore.grossScore === holeScore.hole.par * 2) className = "score-marker double-par";
    return (
      <div className="score-marker-container">
        <div className={className}>
          <div>{holeScore.grossScore}</div>
        </div>
      </div>
    );
  };

  const renderScoreCard = (score: Models.ITurtleScoreWithHoleDetails) => {
    let front9 = score.holeScores.filter((c) => c.hole.number <= 9);
    let back9 = score.holeScores.filter((c) => c.hole.number > 9);
    return (
      <div className="score-card">
        <Table size="small" className="desktop">
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="legend column" title="Legend"></TableCell>
              <TableCell className="column" title="Hole #1">
                1
              </TableCell>
              <TableCell className="column" title="Hole #2">
                2
              </TableCell>
              <TableCell className="column" title="Hole #3">
                3
              </TableCell>
              <TableCell className="column" title="Hole #4">
                4
              </TableCell>
              <TableCell className="column" title="Hole #5">
                5
              </TableCell>
              <TableCell className="column" title="Hole #6">
                6
              </TableCell>
              <TableCell className="column" title="Hole #7">
                7
              </TableCell>
              <TableCell className="column" title="Hole #8">
                8
              </TableCell>
              <TableCell className="column" title="Hole #9">
                9
              </TableCell>
              <TableCell className="column total-cell" title="Front 9 Score">
                Out
              </TableCell>
              <TableCell className="column back9" title="Hole #10">
                10
              </TableCell>
              <TableCell className="column back9" title="Hole #11">
                11
              </TableCell>
              <TableCell className="column back9" title="Hole #12">
                12
              </TableCell>
              <TableCell className="column back9" title="Hole #13">
                13
              </TableCell>
              <TableCell className="column back9" title="Hole #14">
                14
              </TableCell>
              <TableCell className="column back9" title="Hole #15">
                15
              </TableCell>
              <TableCell className="column back9" title="Hole #16">
                16
              </TableCell>
              <TableCell className="column back9" title="Hole #17">
                17
              </TableCell>
              <TableCell className="column back9" title="Hole #18">
                18
              </TableCell>
              <TableCell className="column back9 total-cell" title="Back 9 Score">
                In
              </TableCell>
              <TableCell className="column total-cell" title="Total">
                Total
              </TableCell>
            </TableRow>
            {/* TODO:DF stripedRows={true} */}
            <TableRow>
              <TableCell className="legend column">Yards</TableCell>
              {front9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()}>{holeScore.hole.distance}</TableCell>
              ))}
              <TableCell className="total-cell">{front9.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
              {back9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()} className="column back9">
                  {holeScore.hole.distance}
                </TableCell>
              ))}
              <TableCell className="column back9 total-cell">{back9.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
              <TableCell className="total-cell">{score.holeScores.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="legend column">Handicap</TableCell>
              {front9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()}>{holeScore.hole.handicap}</TableCell>
              ))}
              <TableCell className="total-cell"></TableCell>
              {back9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()} className="column back9">
                  {holeScore.hole.handicap}
                </TableCell>
              ))}
              <TableCell className="column back9 total-cell"></TableCell>
              <TableCell className="total-cell"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="legend column">Par</TableCell>
              {front9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()}>{holeScore.hole.par}</TableCell>
              ))}
              <TableCell className="total-cell">{front9.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
              {back9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()} className="column back9">
                  {holeScore.hole.par}
                </TableCell>
              ))}
              <TableCell className="column back9 total-cell">{back9.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
              <TableCell className="total-cell">{score.holeScores.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            <TableRow>
              <TableCell className="legend column">Score</TableCell>
              {front9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()}>{renderGrossScore(holeScore)}</TableCell>
              ))}
              <TableCell className="total-cell">{front9.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
              {back9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()} className="column back9">
                  {renderGrossScore(holeScore)}
                </TableCell>
              ))}
              <TableCell className="column back9 total-cell">{back9.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
              <TableCell className="total-cell">{score.holeScores.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
            {score.holeScores.some((c) => c.putts !== null) && (
              <TableRow>
                <TableCell className="legend column">Putts</TableCell>
                {front9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()}>{holeScore.putts?.toString() || "-"}</TableCell>
                ))}
                <TableCell className="total-cell">{front9.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
                {back9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()} className="column back9">
                    {holeScore.putts?.toString() || "-"}
                  </TableCell>
                ))}
                <TableCell className="column back9 total-cell">{back9.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
                <TableCell className="total-cell">{score.holeScores.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
              </TableRow>
            )}
            {score.holeScores.some((c) => c.putts !== null) && (
              <TableRow className="slim-row">
                <TableCell className="legend column">GiR</TableCell>
                {front9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()}>{renderGiR(holeScore)}</TableCell>
                ))}
                <TableCell className="total-cell">{front9.filter((c) => c.greenInRegulation).length}</TableCell>
                {back9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()} className="column back9">
                    {renderGiR(holeScore)}
                  </TableCell>
                ))}
                <TableCell className="column back9 total-cell">{back9.filter((c) => c.greenInRegulation).length}</TableCell>
                <TableCell className="total-cell">{score.holeScores.filter((c) => c.greenInRegulation).length}</TableCell>
              </TableRow>
            )}
            {score.holeScores.some((c) => c.fairwayInRegulation !== null) && (
              <TableRow className="slim-row">
                <TableCell className="legend column">FiR</TableCell>
                {front9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()}>{renderFiR(holeScore)}</TableCell>
                ))}
                <TableCell className="total-cell">{front9.filter((c) => c.fairwayInRegulation).length}</TableCell>
                {back9.map((holeScore) => (
                  <TableCell key={holeScore.hole.number.toString()} className="column back9">
                    {renderFiR(holeScore)}
                  </TableCell>
                ))}
                <TableCell className="column back9 total-cell">{back9.filter((c) => c.fairwayInRegulation).length}</TableCell>
                <TableCell className="total-cell">{score.holeScores.filter((c) => c.fairwayInRegulation).length}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className="legend column">Net</TableCell>
              {front9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()}>{holeScore.netScore}</TableCell>
              ))}
              <TableCell className="total-cell">{front9.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
              {back9.map((holeScore) => (
                <TableCell key={holeScore.hole.number.toString()} className="column back9">
                  {holeScore.netScore}
                </TableCell>
              ))}
              <TableCell className="column back9 total-cell">{back9.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
              <TableCell className="total-cell">{score.holeScores.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table size="small" className="mobile">
          <TableHead>
            <TableRow>
              <TableCell className="column" title="Hole #">
                Hole #
              </TableCell>
              <TableCell className="column" title="Yards">
                Yards
              </TableCell>
              <TableCell className="column" title="Par">
                Par
              </TableCell>
              <TableCell className="column" title="Gross Score">
                Score
              </TableCell>
              {score.holeScores.some((c) => c.putts !== null) && (
                <TableCell className="column" title="Putts">
                  Putts
                </TableCell>
              )}
              {score.holeScores.some((c) => c.greenInRegulation !== null) && (
                <TableCell className="column" title="Green in Regulation">
                  GiR
                </TableCell>
              )}
              {score.holeScores.some((c) => c.fairwayInRegulation !== null) && (
                <TableCell className="column" title="Fairway in Regulation">
                  FiR
                </TableCell>
              )}
              <TableCell className="column" title="Net Score">
                Net
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {/* TODO:DF stripedRows={true} */}
            {front9.map((holeScore) => (
              <TableRow key={holeScore.hole.number.toString()}>
                <TableCell>{holeScore.hole.number}</TableCell>
                <TableCell>{holeScore.hole.distance}</TableCell>
                <TableCell>{holeScore.hole.par}</TableCell>
                <TableCell>{renderGrossScore(holeScore)}</TableCell>
                {score.holeScores.some((c) => c.putts !== null) && <TableCell>{holeScore.putts}</TableCell>}
                {score.holeScores.some((c) => c.greenInRegulation !== null) && <TableCell>{renderGiR(holeScore)}</TableCell>}
                {score.holeScores.some((c) => c.fairwayInRegulation !== null) && <TableCell>{renderFiR(holeScore)}</TableCell>}
                <TableCell>{holeScore.netScore}</TableCell>
              </TableRow>
            ))}
            <TableRow className="subtotal">
              <TableCell>Out</TableCell>
              <TableCell>{front9.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{front9.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{front9.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
              {score.holeScores.some((c) => c.putts !== null) && (
                <TableCell>{front9.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
              )}
              {score.holeScores.some((c) => c.greenInRegulation !== null) && (
                <TableCell>{front9.filter((c) => c.greenInRegulation).length}</TableCell>
              )}
              {score.holeScores.some((c) => c.fairwayInRegulation !== null) && (
                <TableCell>{front9.filter((c) => c.fairwayInRegulation).length}</TableCell>
              )}
              <TableCell>{front9.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
            {back9.map((holeScore) => (
              <TableRow key={holeScore.hole.number.toString()}>
                <TableCell>{holeScore.hole.number}</TableCell>
                <TableCell>{holeScore.hole.distance}</TableCell>
                <TableCell>{holeScore.hole.par}</TableCell>
                <TableCell>{renderGrossScore(holeScore)}</TableCell>
                {score.holeScores.some((c) => c.putts !== null) && <TableCell>{holeScore.putts}</TableCell>}
                {score.holeScores.some((c) => c.greenInRegulation !== null) && <TableCell>{renderGiR(holeScore)}</TableCell>}
                {score.holeScores.some((c) => c.fairwayInRegulation !== null) && <TableCell>{renderFiR(holeScore)}</TableCell>}
                <TableCell>{holeScore.netScore}</TableCell>
              </TableRow>
            ))}
            <TableRow className="subtotal">
              <TableCell>In</TableCell>
              <TableCell>{back9.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{back9.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{back9.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
              {score.holeScores.some((c) => c.putts !== null) && (
                <TableCell>{back9.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
              )}
              {score.holeScores.some((c) => c.greenInRegulation !== null) && (
                <TableCell>{back9.filter((c) => c.greenInRegulation).length}</TableCell>
              )}
              {score.holeScores.some((c) => c.fairwayInRegulation !== null) && (
                <TableCell>{back9.filter((c) => c.fairwayInRegulation).length}</TableCell>
              )}
              <TableCell>{back9.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
            <TableRow className="total">
              <TableCell>Total</TableCell>
              <TableCell>{score.holeScores.map((c) => c.hole.distance).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{score.holeScores.map((c) => c.hole.par).reduce((a, b) => a + b)}</TableCell>
              <TableCell>{score.holeScores.map((c) => c.grossScore).reduce((a, b) => a + b)}</TableCell>
              {score.holeScores.some((c) => c.putts !== null) && (
                <TableCell>{score.holeScores.map((c) => c.putts).reduce((a, b) => (a || 0) + (b || 0))}</TableCell>
              )}
              {score.holeScores.some((c) => c.greenInRegulation !== null) && (
                <TableCell>{score.holeScores.filter((c) => c.greenInRegulation).length}</TableCell>
              )}
              {score.holeScores.some((c) => c.fairwayInRegulation !== null) && (
                <TableCell>{score.holeScores.filter((c) => c.fairwayInRegulation).length}</TableCell>
              )}
              <TableCell>{score.holeScores.map((c) => c.netScore).reduce((a, b) => a + b)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="legend  score-marker-container">
          <div className="legend--item">
            <div>Eagle:</div>
            <div className="score-marker swatch eagle">
              <div />
            </div>
          </div>
          <div className="legend--item">
            <div>Birdie:</div>
            <div className="score-marker swatch birdie" />
          </div>
          <div className="legend--item">
            <div>Par:</div>
            <div className="score-marker swatch par" />
          </div>
          <div className="legend--item">
            <div>Bogey:</div>
            <div className="score-marker swatch bogey" />
          </div>
          <div className="legend--item">
            <div>Double Bogey:</div>
            <div className="score-marker swatch double-bogey">
              <div />
            </div>
          </div>
          <div className="legend--item">
            <div>Double Par:</div>
            <div className="score-marker swatch double-par" />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <AppLoading />;
  if (!score) return <div>No Score Found</div>;

  return (
    <div className="score-details">
      {renderHeader(score)}
      <Divider style={dividerStyle} />
      {renderSummary(score)}
      <Divider style={dividerStyle} />
      {renderScoreCard(score)}
    </div>
  );
};
