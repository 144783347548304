import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ITurtle, ITurtleDetails, TurtleStatus } from "../../models/models";
import { handleError } from "../../services/ErrorService";
import TurtleService from "../../services/TurtleService";
import "./PlayerAdminPage.scss";

const encode = (name: string) => {
  return name.replaceAll(" ", "_");
};

const getTurtleStatus = () => {
  const allKeys = Object.keys(TurtleStatus);
  const result = [];
  for (let x = 0; x < allKeys.length / 2; x++) {
    result.push({ name: allKeys[x + allKeys.length / 2], value: allKeys[x] });
  }
  return result;
};
export const PlayerAdminPage = () => {
  const [players, setPlayers] = useState<ITurtleDetails[]>([]);
  const [player, setPlayer] = useState<ITurtleDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlParams = useParams();

  useEffect(() => {
    setIsLoading(true);
    TurtleService.getAll()
      .then((c) => {
        c.sort((a, b) => a.fullNameReverse.localeCompare(b.fullNameReverse));
        setPlayers(c);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (players.length === 0 || !urlParams.playerId) return;
    setPlayer(players.find((c) => c.id === +urlParams.playerId!));
  }, [players, urlParams.playerId]);

  const onPlayerValueChange = (field: string, value: any) => {
    (player! as any)[field] = value;
    console.log(player);
  };

  return (
    <div className="admin-players">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="players__container">
        <List dense={true} className="players__list">
          {players.map((turtle) => (
            <ListItem key={turtle.id}>
              <ListItemButton component={Link} to={`/admin/players/${turtle.id}/${encode(turtle.fullName)}`}>
                <ListItemText primary={turtle.fullNameReverse} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      <div className="player__details">
        {player && (
          <div>
            <h3>{player.fullName}</h3>
            <div className="player__details-info">
              <div className="player__row">
                <TextField
                  label="First Name"
                  value={player.firstName || ""}
                  onChange={(e) => onPlayerValueChange("firstName", e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <TextField
                  label="Middle Name"
                  value={player.middleName || ""}
                  onChange={(e) => onPlayerValueChange("middleName", e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <TextField
                  label="Last Name"
                  value={player.lastName || ""}
                  onChange={(e) => onPlayerValueChange("lastName", e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
              <div className="player__row">
                <TextField
                  label="Short Name"
                  value={player.middleName || ""}
                  onChange={(e) => onPlayerValueChange("shortName", e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
              <div className="player__row">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    label="Status"
                    placeholder="Set Status"
                    value={player.status || TurtleStatus.None}
                    onChange={(e) => onPlayerValueChange("status", e.target.value)}
                    style={{ width: 300 }}
                    autoWidth={false}
                  >
                    {getTurtleStatus().map((d) => (
                      <MenuItem key={d.value} value={d.value}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Start Date"
                    value={player.startDate}
                    onChange={(e) => onPlayerValueChange("startDate", e)}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
