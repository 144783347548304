import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Link } from "react-router-dom";
import * as ScoreEntryModels from "../../models/ScoreEntryModels";
import "./GroupList.scss";

interface Props {
  groups: ScoreEntryModels.Group[];
  dayNumber: number;
}

export const GroupList = (props: Props) => {
  return (
    <div className="group-list">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {props.groups.map((group) => {
              const elements: any[] = [];
              group.turtles.forEach((turtle, index) =>
                elements.push(
                  <TableRow key={turtle.turtle.id}>
                    {index === 0 && (
                      <TableCell className="group-number-cell" align="center" rowSpan={group.turtles.length}>
                        {group.groupNumber}
                      </TableCell>
                    )}
                    <TableCell className={turtle.scoreSummary.isScoreEntered ? "score-complete" : ""}>
                      <Link to={`/admin/entry/${props.dayNumber}/${turtle.turtle.id}`}>{turtle.turtle.fullNameReverse}</Link>
                    </TableCell>
                    <TableCell className={turtle.scoreSummary.isScoreEntered ? "score-complete" : ""}>{turtle.scoreSummary.gross}</TableCell>
                  </TableRow>
                )
              );
              return elements;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
