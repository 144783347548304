import "../css/TurtlePage.scss";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Divider,
  BottomNavigation,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";

// import InfoIcon from 'material-ui/svg-icons/action/info';
// import ScoreIcon from 'material-ui/svg-icons/action/view-list';
// import ClosestToIcon from 'material-ui/svg-icons/places/golf-course';

import { TableHeaderColumnContent } from "../components/common/TableHeaderColumnContent";
import { AppLoading } from "../components/common/AppLoading";

import TurtleService from "../services/TurtleService";
import { RoutingService } from "../services/RoutingService";

import * as Models from "../models/models";
import { useCallback, useEffect, useRef, useState } from "react";
import { handleError } from "../services/ErrorService";
import { Router } from "react-router";

class ClosestToViewModel {
  constructor(public season: Models.ISeasonSummary, public closestTos: Models.IClosestToHoleWinner[]) {
    closestTos = [];
  }
}

export const Turtle = () => {
  const [turtle, setTurtle] = useState<Models.ITurtleWithCareerStats>();
  const [closestTos, setClosestTos] = useState<ClosestToViewModel[]>([]);
  const [selectedNavigationIndex, setSelectedNavigationIndex] = useState<number>(0);
  const [currentSortColumn, setCurrentSortColumn] = useState<string>("dayNumber");
  const [currentSortIsAsc, setCurrentSortIsAsc] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSeasonScores, setIsLoadingSeasonScores] = useState<boolean>(false);
  const [seasonScores, setSeasonScores] = useState<Models.IScoreDetails[]>();
  const [currentSeason, setCurrentSeason] = useState<Models.ISeasonSummaryWithResultsSummary>();
  const [showStatsInMobile, setShowStatsInMobile] = useState<boolean>(false);

  let { id, name, year } = useParams();
  const closestToHolesRef = useRef<HTMLDivElement>(null);
  const trophiesRef = useRef<HTMLDivElement>(null);
  const yearsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Turlte Details`;
    if (!id) {
      return;
    }
    setIsLoading(true);
    TurtleService.getWithCareerStats(+id).then((data) => {
      document.title = `Turtle: ${data.fullName}`;
      var closestTos: any = {};
      var result = new Array<ClosestToViewModel>();
      data.closestToHoles.forEach((c) => {
        if (!closestTos[c.game.season.year]) {
          closestTos[c.game.season.year] = new ClosestToViewModel(c.game.season, []);
          result.push(closestTos[c.game.season.year]);
        }
        closestTos[c.game.season.year].closestTos.push(c);
      });
      data.seasons.sort((a,b) => a.year > b.year ? -1 : 1);
      setTurtle(data);
      setClosestTos(result);
      setIsLoading(false);
      if (data.seasons && data.seasons.length > 0) loadSeasonScores(data.seasons[data.seasons.length - 1]);
    });
  }, [id]);

  useEffect(() => {
    if(year && turtle) {
      loadSeasonScores(turtle?.seasons.find((c) => c.year === +year!));
    }
  }, [year, turtle]);

  const goToStats = () => {
    setSelectedNavigationIndex(0);
    scrollToTop(0);
  };
  const goToClosestToHoles = () => {
    setSelectedNavigationIndex(1);
    scrollToRef(closestToHolesRef);
  };
  const goToTrophies = () => {
    setSelectedNavigationIndex(2);
    scrollToRef(trophiesRef);
  };
  const goToYears = () => {
    setSelectedNavigationIndex(3);
    scrollToRef(yearsRef);
  };

  const scrollToRef = (ref: any) => {
    scrollToTop((ReactDOM.findDOMNode(ref) as any).offsetTop);
  };

  const scrollToTop = (top: number) => {
    window.scrollTo({
      top: top - 5,
      behavior: "smooth",
    });
  };

  const requestSort = (sortBy: string) => {
    let isAscending = currentSortColumn !== sortBy ? true : !currentSortIsAsc;

    seasonScores!.sort((a: any, b: any) => {
      let aValue = a[sortBy]; //TODO:DF this still work?
      let bValue = b[sortBy];
      let value = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      if (!isAscending) value = value * -1;
      return value;
    });
    setCurrentSortColumn(sortBy);
    setCurrentSortIsAsc(isAscending);
    setSeasonScores(seasonScores?.slice());
  };

  const handleSeasonMenuChange = (event: SelectChangeEvent<number>) => {
    const year = event.target.value;
    navigate(`/turtle/${name}/${id}/${year}`);
  };

  const loadSeasonScores = useCallback(
    (season: Models.ISeasonSummaryWithResultsSummary | undefined) => {
      if (!turtle || !season) {
        return;
      }
      setIsLoadingSeasonScores(true);
      setCurrentSeason(season);

      TurtleService.getSeasonScores(turtle.id, season.id)
        .then((turtleScores) => {
          setIsLoadingSeasonScores(false);
          setSeasonScores(turtleScores.scores);
        })
        .catch((error) => handleError(error));
    },
    [turtle?.id]
  );

  const getNonStatsColumnClassNames = () => {
    return showStatsInMobile ? "column nonStatsColumn--unimportant" : "column nonStatsColumn--important";
  };

  const getStatColumnClassNames = () => {
    return showStatsInMobile
      ? "column statsColumn statsColumn--important"
      : "column statsColumn statsColumn--unimportant";
  };

  const hideStats = () => {
    setShowStatsInMobile(false);
  };

  const showStats = () => {
    setShowStatsInMobile(true);
  };

  const renderClosestToHole = (closestTos: ClosestToViewModel[]) => {
    return (
      <div className="closestToContainer" ref={closestToHolesRef}>
        <h4>Closest-To-The-Hole Wins</h4>
        <div className="closestToContainer--winlist">
        {closestTos.map((c) => (
          <div key={c.season.id} className="closestToContainer--section">
            <div className="TurtlePage--data">
              <span className="TurtlePage--label">{c.season.year}:</span>
              {c.closestTos.map((ct) => (
                <div key={ct.id}>
                  Day {ct.game.dayNumber} Hole #{ct.hole.number}
                </div>
              ))}
            </div>
          </div>
        ))}
        </div>
        {closestTos.length === 0 && <div className="no-content">No Closest-To-The-Hole Wins</div>}
      </div>
    );
  };

  const renderTrophies = (turtle: Models.ITurtleWithCareerStats) => {
    return (
      <div className="trophyContainer" ref={trophiesRef}>
        <h4>Trophies</h4>
        {turtle.trophies.map((c) => (
          <div key={c.id} className="trophyContainer--section">
            <div className="TurtlePage--label trophy-data">
              <div>
                {c.season.year}: {c.trophy.name}
              </div>
              <div className="trophy-notes">{c.notes}</div>
            </div>
          </div>
        ))}
        {turtle.trophies.length === 0 && <div className="no-content">No Trophies</div>}
      </div>
    );
  };

  const renderCareerStats = (turtle: Models.ITurtleWithCareerStats) => {
    return (
      <div>
        <div className="careerStatsContainer">
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Started:</div>
            <div className="TurtlePage--data">{turtle.seasons[0].year}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Games:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.games}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Avg Score:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.averageScore}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Handicap:</div>
            <div className="TurtlePage--data">??</div>
          </div>
        </div>
        <div className="careerStatsContainer">
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Best Game:</div>
            <div className="TurtlePage--data">
              <Link to={RoutingService.scoreDetails(turtle, turtle.careerStats?.bestGameScore.id)}>
                {turtle.careerStats?.bestGameScore.grossScore}
              </Link>
            </div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Worst Game:</div>
            <div className="TurtlePage--data">
              <Link to={RoutingService.scoreDetails(turtle, turtle.careerStats?.worstGameScore.id)}>
                {turtle.careerStats?.worstGameScore.grossScore}
              </Link>
            </div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Lowest Score:</div>
            <div className="TurtlePage--data">
              <Link to={RoutingService.scoreDetails(turtle, turtle.careerStats?.lowestScore.id)}>
                {turtle.careerStats?.lowestScore.grossScore}
              </Link>
            </div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Highest Score:</div>
            <div className="TurtlePage--data">
              <Link to={RoutingService.scoreDetails(turtle, turtle.careerStats?.highestScore.id)}>
                {turtle.careerStats?.highestScore.grossScore}
              </Link>
            </div>
          </div>
        </div>
        <div className="careerStatsContainer">
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Hole-in-Ones:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.holeInOnes}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Albatrosses:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.albatrosses}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Eagles:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.eagles}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Birdies:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.birdies}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Pars:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.pars}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Bogeys:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.bogeys}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Double Bogeys:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.doubleBogeys}</div>
          </div>
          <div className="careerStatsContainer--section">
            <div className="TurtlePage--label">Double Pars:</div>
            <div className="TurtlePage--data">{turtle.careerStats?.doublePars}</div>
          </div>
        </div>
      </div>
    );
  };
  const renderScoresGrid = () => {
    if (isLoadingSeasonScores) return <AppLoading />;
    return (
      <div className="scoreTable" ref={yearsRef}>
        <div className="stat-toggle-buttons">
          {showStatsInMobile ? (
            <Button onClick={hideStats}>Show Summary</Button>
          ) : (
            <Button onClick={showStats}>Show Stats</Button>
          )}
        </div>
        <Table>
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="column" title="Day Number" style={{ width: 35 }}>
                <TableHeaderColumnContent
                  column="game.dayNumber"
                  text="Day"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getNonStatsColumnClassNames()} title="Course Name" style={{ width: 195 }}>
                Name
              </TableCell>
              <TableCell
                className={getNonStatsColumnClassNames()}
                title="Gross"
                style={{ minWidth: 50, paddingLeft: 12 }}
              >
                <TableHeaderColumnContent
                  column="grossScore"
                  text="Gross"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell
                className={getNonStatsColumnClassNames()}
                title="Total Net"
                style={{ minWidth: 50, paddingLeft: 20 }}
              >
                <TableHeaderColumnContent
                  column="netScore"
                  text="Net"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Hole In Ones">
                <TableHeaderColumnContent
                  column="holeInOnes"
                  text="Hole-In-One"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Eagles">
                <TableHeaderColumnContent
                  column="eagles"
                  text="Eagle"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Birdies">
                <TableHeaderColumnContent
                  column="birdies"
                  text="Birdie"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Pars">
                <TableHeaderColumnContent
                  column="pars"
                  text="Par"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Bogeys">
                <TableHeaderColumnContent
                  column="bogeys"
                  text="Bogey"
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Doube Bogeys">
                <TableHeaderColumnContent
                  column="doubleBogeys"
                  text={
                    <span>
                      <span className="long-header">Double Bogey</span>
                      <span className="short-header">2x Bogey</span>
                    </span>
                  }
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
              <TableCell className={getStatColumnClassNames()} title="Double Pars">
                <TableHeaderColumnContent
                  column="doublePars"
                  text={
                    <span>
                      <span className="long-header">Double Par</span>
                      <span className="short-header">2x Par</span>
                    </span>
                  }
                  currentSortColumn={currentSortColumn}
                  currentSortIsAsc={currentSortIsAsc}
                  requestSort={requestSort}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {/*stripedRows={true}  */}
            {seasonScores
              ? seasonScores.map((s, x) => (
                  <TableRow key={s.id}>
                    <TableCell className="column" style={{ width: 35 }}>
                      {s.game.dayNumber}
                    </TableCell>
                    <TableCell className={getNonStatsColumnClassNames() + " courseColumn"} style={{ width: 195 }}>
                      <Link to={RoutingService.course(s.game.tee.course)}>
                        <span className="name">{s.game.tee.course.name}</span>
                      </Link>
                    </TableCell>
                    <TableCell className={getNonStatsColumnClassNames() + " grossScoreColumn"} style={{ minWidth: 50 }}>
                      <Link to={RoutingService.scoreDetails(turtle!, s.id)}>{s.grossScore}</Link>
                    </TableCell>
                    <TableCell className={getNonStatsColumnClassNames() + " netScoreColumn"} style={{ minWidth: 50 }}>
                      {s.netScore}
                    </TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.holeInOnes}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.eagles}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.birdies}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.pars}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.bogeys}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.doubleBogeys}</TableCell>
                    <TableCell className={getStatColumnClassNames()}>{s.doublePars}</TableCell>
                  </TableRow>
                ))
              : undefined}
          </TableBody>
        </Table>
      </div>
    );
  };
  const renderSeasons = (turtle: Models.ITurtleWithCareerStats) => {
    return (
      <div className="seasonGrid">
        <div className="scoreContainer">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={currentSeason ? currentSeason.year : 2023}
              onChange={handleSeasonMenuChange}
              style={{ width: 300 }}
              autoWidth={false}
            >
              {turtle.seasons.map((season: Models.ISeasonSummary) => (
                <MenuItem key={season.id} value={season.year} className="yearContainer">
                  Scores in {season.year.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {currentSeason && (
            <div className="position-container">
              <div>Final Day Position: {currentSeason.finalDayPosition}</div>
              <div>
                Final Position:
                <Link to={RoutingService.seasonDetails(currentSeason)}>{currentSeason.finalPosition}</Link>
              </div>
            </div>
          )}
          {renderScoresGrid()}
        </div>
      </div>
    );
  };

  const renderTurtleContent = (turtle: Models.ITurtleWithCareerStats) => {
    const trophyIcon = (
      <svg style={{ fill: "rgba(0, 0, 0, 0.54)" }} version="1.1" width="24" height="24" viewBox="0 0 24 24">
        <path d="M20.2,2H19.5H18C17.1,2 16,3 16,4H8C8,3 6.9,2 6,2H4.5H3.8H2V11C2,12 3,13 4,13H6.2C6.6,15 7.9,16.7 11,17V19.1C8.8,19.3 8,20.4 8,21.7V22H16V21.7C16,20.4 15.2,19.3 13,19.1V17C16.1,16.7 17.4,15 17.8,13H20C21,13 22,12 22,11V2H20.2M4,11V4H6V6V11C5.1,11 4.3,11 4,11M20,11C19.7,11 18.9,11 18,11V6V4H20V11Z" />
      </svg>
    );

    return (
      <div>
        {renderCareerStats(turtle)}
        {renderClosestToHole(closestTos)}
        {renderTrophies(turtle)}
        {renderSeasons(turtle)}
        {/* TODO:DF Fix this */}
        {/* <BottomNavigation className="bottomNav" selectedIndex={selectedNavigationIndex} style={{ position: "fixed", left: 0, bottom: 0, zIndex:2 }} >
                <BottomNavigationItem
                    onClick={goToStats}
                    label="Summary"
                    icon={<InfoIcon />}
                />
                <BottomNavigationItem
                    onClick={goToClosestToHoles}
                    className="closestToIcon"
                    label="Closest-To"
                    icon={<ClosestToIcon />}
                />
                <BottomNavigationItem
                    onClick={goToTrophies}
                    label="Trophies"
                    icon={trophyIcon}
                />
                <BottomNavigationItem
                    onClick={goToYears}
                    label="Years"
                    icon={<ScoreIcon />}
                />
            </BottomNavigation> */}
      </div>
    );
  };

  return (
    <div className="TurtlePage">
      <h2>{turtle ? turtle.fullName : ""}</h2>
      {isLoading || !turtle ? <AppLoading /> : renderTurtleContent(turtle!)}
    </div>
  );
};
