import * as Models from '../models/models';
import { HttpService } from './HttpService';


class StatsService {
    get = (): Promise<any> => {
        return HttpService.get<Models.ICourse>(`/api/stats/`)
            .then(response => response).catch(error => Promise.reject(error));
    };
}

export default new StatsService();