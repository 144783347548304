import { Link, useParams } from "react-router-dom";
import { RoutingService } from "../services/RoutingService";
import TrophyService from "../services/TrophyService";
import { ITrophyAward } from "../models/models";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Divider, BottomNavigation } from "@mui/material";

import { AppLoading } from "../components/common/AppLoading";
import "../css/TrophyDetailsPage.scss";
import { useEffect, useState } from "react";

interface IState {
  winners: ITrophyAward[];
  isLoading: boolean;
}

export const TrophyDetailsPage = () => {
  const [winners, setWinners] = useState<ITrophyAward[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let { id } = useParams();

  useEffect(() => {
    document.title = `Trophy Details`;
    if (!id) {
      return;
    }
    setIsLoading(true);
    TrophyService.getHistory(+id).then((data) => {
      document.title = `Trophy: ${data[0].trophy.name}`;
      setWinners(data);
      setIsLoading(false);
    });
  }, [id]);

  if (isLoading || winners.length === 0) return <AppLoading />;

  return (
    <div className="TrophyDetailsPage">
      <div className="info">
        <h1>{winners[0].trophy.name}</h1>
        <h4>{winners[0].trophy.description}</h4>
      </div>
      <div className="table">
        <Table>
           
          {/* TODO:DF fixed header */}
          <TableHead>
            <TableRow>
              <TableCell className="year column" title="Year Won">
                Year
              </TableCell>
              <TableCell className="name column" title="Winner">
                Winner
              </TableCell>
              <TableCell className="notes column" title="Notes">
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {winners.map((t) => (
              <TableRow key={t.id}>
                <TableCell className="year column">{t.season.year}</TableCell>
                <TableCell className="name column">
                  <Link to={RoutingService.turtle(t.turtle)}>{t.turtle.fullName}</Link>
                </TableCell>
                <TableCell className="notes column">{t.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
