import SeasonService from "../../services/SeasonService";
import * as Models from "../../models/models";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import "./SeasonAdminPage.scss";
import { TrophyAdmin } from "../../components/admin/TrophyAdmin";
import { RosterAdmin } from "../../components/admin/RosterAdmin";
import { GroupAdmin } from "../../components/admin/GroupAdmin";
import { handleError } from "../../services/ErrorService";
import { GameEntry } from "../../components/admin/GameEntry";
import CourseService from "../../services/CourseService";
import { Game, Tee } from "../../models/ScoreEntryModels";
export const SeasonAdminPage = () => {
  const [seasons, setSeasons] = useState<Models.ISeasonSummary[]>([]);
  const [season, setSeason] = useState<Models.ISeasonSummary>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [hotelAddress, setHotelAddress] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [games, setGames] = useState<Models.IGameWithCourseSummaryAdmin[]>([]);
  const [courses, setCourses] = useState<Models.ICourseSummary[]>([]);

  const urlParams = useParams();

  useEffect(() => {
    document.title = "Admin: Seasons";
    SeasonService.getSeasonSummaries().then((seasons) => setSeasons(seasons));
    CourseService.getAll().then((courses) => {
      courses.sort((a, b) => a.name.localeCompare(b.name));
      setCourses(courses);
    });
  }, []);

  useEffect(() => {
    setHotelAddress(season?.hotelAddress);
  }, [season]);

  useEffect(() => {
    if (seasons.length > 0 && urlParams.year) {
      const selectedSeason = seasons.find((c) => c.year === +urlParams.year!);
      if (!selectedSeason) {
        return;
      }
      setSeason(selectedSeason);
      setIsLoading(true);
      SeasonService.getAdminGames(selectedSeason.id)
        .then((games) => {
          setGames(games);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoading(false);
        });
    }
  }, [seasons, urlParams.year]);

  const onHotelAddressChange = async (e: ChangeEvent<HTMLInputElement>) => {
    season!.hotelAddress = e.target.value;
    setHotelAddress(season?.hotelAddress);
  };

  const saveSeason = async () => {
    setIsLoading(true);
    await SeasonService.updateSeason(season, games);
    setIsLoading(false);
  };
  const a11yProps = (index: number) => {
    return {
      id: `season-tab-${index}`,
      "aria-controls": `season-tabpanel-${index}`,
    };
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const addSeason = async () => {
    setIsLoading(true);
    const season = await SeasonService.addSeason();
    seasons.splice(0, 0, season);
    setSeasons(seasons.slice());
    setSeason(season);
    setIsLoading(false);
  };

  const addGame = () => {
    if (!season) {
      return;
    }
    const newGames = games.slice();
    const previousDate = newGames.length > 0 ? newGames[newGames.length - 1].date : undefined;
    if (previousDate) {
      previousDate.setDate(previousDate.getDate() + 1);
    }
    newGames.push({
      id: 0,
      dayNumber: newGames.length + 1,
      date: previousDate || new Date(),
      isComplete: false,
      averageGross: 0,
      season: season!,
    });
    setGames(newGames);
  };

  const onGameTeeChange = (
    game: Models.IGameWithCourseSummaryAdmin,
    tee: Models.ITeeSummary,
    course: Models.ICourseSummary
  ) => {
    const convertedTee = tee as Models.ITee;
    convertedTee.course = course as any;
    game.tee = convertedTee;
    setGames(games.slice());
  };

  const onGameDateChange = (game: Models.IGameWithCourseSummaryAdmin, date?: Date) => {
    game.date = date;
    setGames(games.slice());
  };

  return (
    <div className="admin-seasons">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="season-list">
        <Button size="small" color="primary" onClick={addSeason}>
          Add Season
        </Button>
        <List dense={true}>
          {seasons.map((season) => (
            <ListItem key={season.id}>
              <ListItemButton component={Link} to={`/admin/seasons/${season.year}`}>
                <ListItemText primary={season.year} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {season && (
        <div className="season-content">
          <h3>{season.year}</h3>
          <Button size="small" onClick={saveSeason}>
            Update Address / Games
          </Button>
          <div className="hotel-address">
            <TextField
              label="Hotel Address"
              value={hotelAddress || ""}
              onChange={onHotelAddressChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          {courses && (
            <div className="games">
              <Button size="small" onClick={addGame}>
                Add Game
              </Button>
              <div className="games-entry">
                {games.map((game) => (
                  <div key={game.dayNumber} className="game-entry">
                    <GameEntry
                      game={game}
                      courses={courses}
                      onTeeChange={(tee, course) => onGameTeeChange(game, tee, course)}
                      onDateChange={(date) => onGameDateChange(game, date)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {!courses && <div className="games">Loading Courses</div>}

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={currentTab} onChange={onTabChange} aria-label="Season Admin Options">
              <Tab label="Roster" {...a11yProps(0)} />
              <Tab label="Groups" {...a11yProps(1)} />
              <Tab label="Trophies" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {currentTab === 0 && (
            <div className="roster-tab">
              <RosterAdmin season={season} />
            </div>
          )}
          {currentTab === 1 && (
            <div className="groups-tab">
              <GroupAdmin season={season} />
            </div>
          )}
          {currentTab === 2 && (
            <div className="trophies-tab">
              <TrophyAdmin season={season} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
