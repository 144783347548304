import * as React from "react";
import { Link } from "react-router-dom";

import * as Models from "../models/models";
import { RoutingService } from "../services/RoutingService";
import { WeatherService } from "../services/WeatherService";
import CourseService from "../services/CourseService";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GroupIcon from "@mui/icons-material/Groups2";
import DirectionsIcon from "@mui/icons-material/TimeToLeave";
import { Button } from "@mui/material";
import "./GameDaySummary.scss";

interface IState {
  isLoading: boolean;
  isMinimized: boolean;
  weather?: Models.IWeatherReport;
}

interface IProps {
  game: Models.IGame;
  season: Models.ISeasonSummary;
  isC?: boolean;
}

export class GameDaySummary extends React.Component<IProps, IState> {
  private weatherService: WeatherService;

  constructor(props: any) {
    super(props);
    this.state = { isLoading: false, isMinimized: true };
    this.weatherService = new WeatherService();
  }

  componentDidMount() {
    if (this.props.game && !this.props.game.isComplete && !this.state.weather) {
      this.weatherService
        .getByGame(this.props.game.id)
        .then((weather) => {
          if (weather) this.setState({ weather: weather });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  private formatTime(date?: Date) {
    return date ? `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}` : "";
  }

  private renderCompleteGame = () => {
    return (
      <div className="completedGame">
        <div className="resultCell left-cell">
          <Button
            className="icon-button--icon-top"
            component={Link}
            to={RoutingService.gameDetails(this.props.game, this.props.season)}
            startIcon={<FormatListNumberedIcon />}
          >
            Results
          </Button>
          {/*               
                <Link to={RoutingService.gameDetails(this.props.game, this.props.season)}>
                    <FormatListNumberedIcon fontSize="large" />
                    <div>Results</div>
                </Link> */}
        </div>
        <div className="detailsCell">
          <div className="detailRow">
            <label>Low Gross: </label>
            <Link
              to={RoutingService.scoreDetails(this.props.game.lowGrossScore.turtle, this.props.game.lowGrossScore.id)}
            >
              {this.props.game.lowGrossScore.turtle.fullName}
            </Link>
          </div>
          <div className="detailRow">
            <label>Low Net: </label>
            <Link to={RoutingService.scoreDetails(this.props.game.lowNetScore.turtle, this.props.game.lowNetScore.id)}>
              {this.props.game.lowNetScore.turtle.fullName}
            </Link>
          </div>
          <div className="detailRow">
            <label>Average Gross: </label>
            <span>{this.props.game.averageGross}</span>
          </div>
        </div>
      </div>
    );
  };

  private renderTemperature = (temp: number) => {
    return Math.round(this.props.isC ? temp - 273.15 : temp * (9 / 5) - 459.67);
  };

  private renderWeather = () => {
    if (!this.state.weather) return <div></div>;
    let lowTemp = this.renderTemperature(this.state.weather.lowTemperature);
    let highTemp = this.renderTemperature(this.state.weather.highTemperature);
    return (
      <div>
        <div>
          <img
            src={`http://openweathermap.org/img/w/${this.state.weather.icon}.png`}
            alt={this.state.weather.conditions}
          />
          {/* <div>{this.state.weather.conditions}</div> */}
        </div>
        <div>
          {lowTemp !== highTemp ? (
            <span>
              {lowTemp}&deg;<span className="joinner">to</span>{highTemp}&deg;
            </span>
          ) : (
            <span>{highTemp}&deg;</span>
          )}
        </div>
      </div>
    );
  };

  private renderFutureGame = () => {
    return (
      <div className="futureGame">
        <div className="weatherContainer left-cell">{this.renderWeather()}</div>
        <div className="infoContainer">
          <div>
            <Button
              className="icon-button--icon-top"
              component={Link}
              to={`/groups/${this.props.game.season.year}`}
              startIcon={<GroupIcon />}
            >
              Groups
            </Button>
          </div>
          <div>
            <Button
              className="icon-button--icon-top"
              href={CourseService.createDirection(this.props.game.tee.course, this.props.season)}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<DirectionsIcon />}
            >
              Directions
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private renderGame(): JSX.Element {
    return (
      <div>
        <div className="gameDaySummary--container">
          <div className="header">
            <div className="day" title={this.props.game.date!.toDateString()}>
              Day {this.props.game.dayNumber}
            </div>
            <div className="course">{this.props.game.tee.course.name}</div>
            <div className="time">@ {this.formatTime(this.props.game.date)}</div>
          </div>
          {this.props.game.isComplete ? this.renderCompleteGame() : this.renderFutureGame()}
        </div>
      </div>
    );
  }

  public render() {
    return <div className="gameDaySummary">{this.state.isLoading ? <span>Loading...</span> : this.renderGame()}</div>;
  }
}
