import { AudioConfig, SpeechRecognizer, SpeechConfig } from "microsoft-cognitiveservices-speech-sdk";
import { HttpService } from "./HttpService";


class VerbalEntryService {
  private _token: string | undefined = undefined;
  private _region: string | undefined = undefined;
  public start = async (callback: (result: string) => void): Promise<boolean> => {
    console.log("Starting speech...");
    try {
      if (!this._token || !this._region) {
        var response = await HttpService.get<{ token: string, region: string }>("/api/ScoreEntry/speech/token");
        this._token = response.token;
        this._region = response.region;
      }
      const speechConfig = SpeechConfig.fromAuthorizationToken(this._token, this._region);
      speechConfig.enableDictation();
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
      recognizer.recognizeOnceAsync((result: any) => {
        // Interact with result
        console.log("Speech recognized");
        console.log(result);
        callback(this.processStream(result.text));
      });
      // recognizer.close(() => {
      //   console.log("Speech closed");
      // })
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private processStream(data: string) {
    if (!data) {
      return data;
    }
    return this.replaceLexiconNumbers(data).replace(/[^\d]/g, "");
  }

  public processSingleDigit = (data: string): number[] => {
    const results: number[] = [];
    for (let x = 0; x < data.length; x++) {
      let value = data[x];
      results.push(+value);
    }
    return results;
  }

  public processBoolean = (data: string): boolean[] => {
    const results: boolean[] = [];
    for (let x = 0; x < data.length; x++) {
      let value = data[x];
      results.push(+value === 1);
    }
    return results;
  }

  public processWithTens = (data: string): number[] => {
    const results: number[] = [];
    data = data.replaceAll("10", "A");
    for (let x = 0; x < data.length; x++) {
      let value = data[x];
      if (value === "A") value = "10";
      results.push(+value);
    }
    return results;
  }

  // private processStream(data: string) {
  //     const results = [];
  //     data = this.replaceLexiconNumbers(data).replace(/[^\d]/, "").replace("10", "A");
  //     for (let x = 0; x < data.length; x++) {
  //         let value = data[x];
  //         if (value === "A") value = "10";
  //         results.push(+value);
  //     }
  //     return results;
  // }


  // private processRawStream(data: string) {
  //     const results: number[] = [];
  //     console.log({ rawString: data });

  //     data = this.replaceLexiconNumbers(data);
  //     for (let x = 0; x < data.length; x++) {
  //         results.push(+data[x]);
  //     }
  //     return results;
  // }


  private replaceLexiconNumbers(data: string) {
    return data
      .toLowerCase()
      .replace("ten", "10")
      .replace("nine", "9")
      .replace("eight", "8")
      .replace("seven", "7")
      .replace("six", "6")
      .replace("five", "5")
      .replace("four", "4")
      .replace("three", "3")
      .replace("two", "2")
      .replace("too", "2")
      .replace("to", "2")
      .replace("one", "1");
  }

}

export default new VerbalEntryService();