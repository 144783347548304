export enum UserRoles { Regular = 0, Admin = 1 }

export interface IHoleScoringStats {
    holeInOnes: number;
    albatrosses: number;
    eagles: number;
    birdies: number;
    pars: number;
    bogeys: number;
    doubleBogeys: number;
    doublePars: number;
}

export interface IHoleScore {
    hole: IHoleSummary;
    netScore: number;
    grossScore: number;
    fairwayInRegulation?: boolean;
    greenInRegulation?: boolean;
    putts?: number;
}

export interface ITurtleScoreWithHoleDetails {
    holeScores: IHoleScore[];
    turtle: ITurtle;
    score: IScoreDetails;
}

export interface ICareerStats extends IHoleScoringStats {
    id: number;
    games: number;
    averageScore: number;


    bestGameScore: IScoreSummary;
    worstGameScore: IScoreSummary;
    lowestScore: IScoreSummary;
    highestScore: IScoreSummary;
}

export interface ITurtleScoreDetails {
    turtle: ITurtle;
    scores: IScoreDetails[];
}

export interface IScoreDetails extends IHoleScoringStats {
    id: number;
    grossScore: number;
    netScore: number;
    played: boolean;
    game: IGameWithCourseSummary;
    handicap: number;
}

export interface ITurtleWithCareerStats extends ITurtle {
    seasons: ISeasonSummaryWithResultsSummary[];
    careerStats: ICareerStats;
    trophies: ITrophyAward[];
    closestToHoles: IClosestToHoleWinner[];
}

export interface ITrophyAward {
    id: number;
    turtle: ITurtle;
    season: ISeasonSummary;
    trophy: ITrophy;
    notes: string;
}

export interface ITrophy {
    id: number;
    name: string;
    description: string;
}

export enum TurtleStatus { None = 0, Active = 1, Inactive = 2, Slider = 3, InactiveSlider  = 4}

export interface ITurtleDetails extends ITurtle {
    status: TurtleStatus;
    startDate?: Date;
    turtleHandicap?: number;
    middleName?: string;
    lastDate?: Date;
    address?: string;
    city?: string;
    province?: string;
    country?: any;
    postalCode?: any;
    phone?: any;
    email?: any;
    notes?: any;
}

export interface ITurtle {
    id: number;
    fullName: string;
    fullNameReverse: string;
    shortName: string;
    firstName: string;
    lastName: string;
    turtleHandicap?: number;
}

export interface ICourseSummaryWithTees {
    id: number;
    name: string;
    tees: ITeeSummary[];
}

export interface ICourseSummary {
    id: number;
    name: string;
}

export interface ICourseWithLowest {
    course: ICourse;
    lowGross: IScoreSummary;
}

export interface ICourse extends ICourseSummary {
    postalCode: string;
    address: string;
    city: string;
    province: string;
    country: string;
    phone: string;
    email: string;
    url: string;
    notes: string;
}

export interface ICourseDetails extends ICourse {
    games: ICourseHistory[];
    tees: ITeeSummary[];
}

export interface ICourseHistory extends IGameSummary {
    season: ISeasonSummary;
    lowGrossScore: IScoreSummary;
    highGrossScore: IScoreSummary;
    lowNetScore: IScoreSummary;
    highNetScore: IScoreSummary;
    tee: ITeeSummary;
}

export interface ITeeSummary {
    id: number;
    name: string;
    rating: number;
    slope: number;
    yardage: number;
}

export interface ITee extends ITeeSummary {
    course: ICourse;
}

export interface IMessage {
    id: number;
    messageText: string;
    date: Date;
    isActive: boolean;
    priority: number;
}

export interface IScoreSummary {
    id: number;
    turtle: ITurtle;
    grossScore: number;
    netScore: number;
    game: IGameWithCourseSummary;
    played: boolean;
}

export interface IGameScoreSummary {
    grossScore: number;
    netScore: number;
    played: boolean;
    gameId: number;
    scoreId: number;
    date: Date;
    dayNumber: number;
    isComplete: boolean;
    fairwaysInRegulation?: number;
    fairwaysInRegulationPercent?: number;
    greensInRegulation?: number;
    puttsAverage?: number;
    putts?: number;
}

export interface ISeasonGames {
    season: ISeasonSummary;
    games: IGame[];
}

export interface IGame extends IGameSummary {
    notes?: string;
    tee: ITee;
    scores: IScoreSummary[];
    groups: any[];
    messages: IMessage[];
    closestToHoleWinners: IClosestToHoleWinner[];
    lowNetScore: IScoreSummary;
    lowGrossScore: IScoreSummary;
    season: ISeasonSummary;
    averageGross: number;
}

export interface IClosestToHoleWinner {
    id: number;
    hole: IHoleSummary;
    turtle: ITurtle;
    game: IGameSummary;
}

export interface IHoleSummary {
    id: number;
    par: number;
    distance: number;
    handicap: number;
    number: number;
    tee: ITee;
}

export interface IGameWithCourseSummaryAdmin extends IGameSummary { 
    tee?: ITee;
}

export interface IGameWithCourseSummary extends IGameSummary {
    tee: ITee;
}

export interface IGameSummary {
    id: number;
    dayNumber: number;
    date?: Date;
    isComplete: boolean;
    averageGross: number;
    season: ISeasonSummary;
}


export interface IRoster {
    id: string;
    firstName: string;
    lastName: string;
    fullNameReverse: string;
    fullName: string;
}

export interface ITieBreakerData {
    turtleId: number;
    holeInOnes: number;
    albatroses: number;
    eagles: number;
    birdies: number;
    pars: number;
    bogeys: number;
}
export interface ISeasonSummaryWithResultsSummary extends ISeasonSummary {
    finalDayPosition: number;
    finalPosition: number;
}
export interface ISeasonSummary {
    id: number;
    year: number;
    hotelAddress?: string;
}

export interface ISeasonScoringDetails extends ISeasonDetails {
    games: IGameSummary[];
    turtleScores: Array<ITurtleSeasonScoreSummary>;
}

export interface ITurtleSeasonScoreSummary {
    grossOffset: number;
    netOffset: number;
    turtle: ITurtle;
    grossScore: number;
    netScore: number;
    grossRank: number;
    netRank: number;
    improvement: number;
    seasonHandicap: number;
    fairwaysInRegulation?: number;
    fairwaysInRegulationPercent?: number;
    greensInRegulation?: number;
    putts?: number;
    puttsAverage?: number;
    scores: IGameScoreSummary[];
}

export interface ISeasonDetails extends ISeasonSummary {
    hotelAddress?: any;
}

export interface IUser {
    email: string;
    turtle: ITurtle;
}

export interface IIdentity {
    username: string;
    role?: number;
    turtle?: ITurtle;
}

export interface IWeatherReport {
    date: Date;
    highTemperature: number;
    lowTemperature: number;
    conditions: string;
    icon: string;
}

export interface IGamesPlayed {
    turtle: ITurtle;
    count: number;
}
export interface IStatTotals {
    gamesPlayed: number;
    holesPlayed: number;
    turtles: number;
    seasons: number;
    strokes: number;
}

export interface IJekyllAndHydeChampion {
    turtle: ITurtle;
    lowScore: IScoreSummary;
    highScore: IScoreSummary;
}

export interface IHoleStat {
    turtle: ITurtle;
    scores: IScoreDetails[];
}

export interface IHoleScoreStat {
    turtle: ITurtle;
    scores: IHoleScoreStatScore[];
}

export interface IHoleScoreStatScore {
    hole: IHoleSummary;
    game: IGameWithCourseSummary;
    grossScore: number;
    netScore: number;
}

export interface IStats {
    lowestGrossScores: IScoreSummary[];
    mostGamesPlayed: IGamesPlayed[];
    totals: IStatTotals;
    jekyllAndHydeChampion: IJekyllAndHydeChampion;
    holeInOnes: IHoleStat[];
    eagles: IHoleStat[];
    par6s: IHoleScoreStat[];
}


export interface Group {
    turtles: ITurtle[];
    groupNumber: number;
}

export interface GameGroup {
    game: IGameWithCourseSummary;
    groups: Group[];
}

export interface SeasonGroups {
    gameGroups: GameGroup[];
}
export interface RosterResponse {
    roster: ITurtle[];
    availableTurtles: ITurtle[];
}
