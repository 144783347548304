import { useEffect, useState } from "react";
import { Hole, HoleScoreEntry } from "../../models/ScoreEntryModels";
import MicOnIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CheckIcon from "@mui/icons-material/CheckCircle";

import "./ScoreCard.scss";
import { Checkbox, IconButton, TextField } from "@mui/material";
import VerbalEntryService from "../../services/VerbalEntryService";

interface Props {
  onValueChange: (field: string, holeId: number, value: any) => void;
  holes: Hole[];
  holeScores: HoleScoreEntry[];
}

interface ScoreRow {
  name: string;
  field: string;
  showTotals: boolean;
  isReadOnly: boolean;
  speechProcessor?: (data: string) => (number | boolean)[];
  isBoolean?: boolean;
}

interface HoleIdMapping {
  [holeId: number]: number;
}

const addTotals = (a: any, b: any) => +(a || 0) + +(b || 0);
const holeRows: ScoreRow[] = [
  { name: "", field: "number", showTotals: false, isReadOnly: true },
  { name: "Distance", field: "distance", showTotals: true, isReadOnly: true },
  { name: "Handicap", field: "handicap", showTotals: false, isReadOnly: true },
  { name: "Par", field: "par", showTotals: true, isReadOnly: true },
];
const scoreRows: ScoreRow[] = [
  {
    name: "Gross",
    field: "grossScore",
    showTotals: true,
    isReadOnly: false,
    speechProcessor: VerbalEntryService.processWithTens,
  },
  {
    name: "Putts",
    field: "putts",
    showTotals: true,
    isReadOnly: false,
    speechProcessor: VerbalEntryService.processSingleDigit,
  },
  {
    name: "FiR",
    field: "fairwayInRegulation",
    showTotals: true,
    isReadOnly: false,
    isBoolean: true,
    speechProcessor: VerbalEntryService.processBoolean,
  },
  {
    name: "Net",
    field: "adjustedScore",
    showTotals: true,
    isReadOnly: true,
  },
  {
    name: "GiR",
    field: "greenInRegulation",
    showTotals: true,
    isReadOnly: true,
    isBoolean: true,
  },
];

const ScoreEntry = (props: {
  row: ScoreRow;
  hole: HoleScoreEntry;
  onValueChange: (field: string, holeId: number, value: any) => void;
}) => {
  const { row, hole, onValueChange } = props;
  return row.isBoolean ? (
    // Don't really support checkbox for edit yet
    row.isReadOnly ? (
      (hole as any)[row.field] ? (
        <CheckIcon className="score-entry--check" />
      ) : (
        <></>
      )
    ) : (
      //<input
      //   type="checkbox"
      //   readOnly={row.isReadOnly}
      //   checked={(hole as any)[row.field]}
      //   onChange={(e) => onValueChange(row.field, hole.holeId, e.target.checked)}
      // />
      <Checkbox
        readOnly={row.isReadOnly}
        size="small"
        checked={(hole as any)[row.field] || false}
        indeterminate={(hole as any)[row.field] === undefined || (hole as any)[row.field] === null}
        onChange={(e) => onValueChange(row.field, hole.holeId, e.target.checked)}
      />
    )
  ) : (
    <TextField
      type="text"
      disabled={row.isReadOnly}
      value={(hole as any)[row.field] === undefined ? "" : (hole as any)[row.field]}
      error={!!hole.errors[row.field]}
      size="small"
      className="text-field"
      onChange={(e) => onValueChange(row.field, hole.holeId, +e.target.value)}
    />
  );
};

export const ScoreCard = (props: Props) => {
  const [holeIdMapping, setHoleIdMapping] = useState<HoleIdMapping>({});
  const [isSpeechRunning, setIsSpeechRunning] = useState<boolean>(false);
  const [speechField, setSpeechField] = useState<string>();

  useEffect(() => {
    if (!props.holes || !props.holeScores) {
      return;
    }
    const result: any = {};
    props.holeScores.forEach((hs) => {
      result[hs.holeId] = props.holes.find((c) => c.id === hs.holeId)?.number;
    });
    setHoleIdMapping(result);
  }, [props.holes, props.holeScores]);

  const onValueChange = (field: string, holeId: number, value: number) => {
    props.onValueChange(field, holeId, value);
  };

  const startSpeech = async (field: string) => {
    const started = await VerbalEntryService.start((data) => {
      console.log(`Speech Completed with: ${data} for ${field}`);
      setSpeechField(undefined);
      setIsSpeechRunning(false);
      if (!data) {
        return;
      }
      const values = scoreRows.find((c) => c.field === field)!.speechProcessor!(data);
      values.forEach((value, index) => {
        props.onValueChange(field, props.holes.find((h) => h.number === index + 1)!.id, value);
      });
    });
    if (!started) {
      alert("SpeechEntry failed to start");
    } else {
      setSpeechField(field);
      setIsSpeechRunning(true);
    }
  };

  return (
    <div className="score-card">
      <table>
        <thead>
          {holeRows.map((row) => (
            <tr key={row.field}>
              <td>{row.name}</td>
              {/* Out */}
              {props.holes
                .filter((c) => c.number <= 9)
                .map((h) => (
                  <td key={h.id}>{(h as any)[row.field]}</td>
                ))}
              <td>
                {/* Out Total */}
                {row.showTotals
                  ? props.holes
                      .filter((c) => c.number <= 9)
                      .map((c: any) => c[row.field])
                      .reduce((a: any, b: any) => a + b)
                      .toString()
                  : ""}
                {row.field === "number" && "Out"}
              </td>
              {/* In */}
              {props.holes
                .filter((c) => c.number >= 10)
                .map((h) => (
                  <td key={h.id}>{(h as any)[row.field]}</td>
                ))}
              <td>
                {/* In Total */}
                {row.showTotals
                  ? props.holes
                      .filter((c) => c.number >= 10)
                      .map((c: any) => c[row.field])
                      .reduce((a: any, b: any) => a + b)
                      .toString()
                  : ""}
                {row.field === "number" && "In"}
              </td>

              <td>
                {/* Total */}
                {row.showTotals
                  ? props.holes
                      .map((c: any) => c[row.field])
                      .reduce((a: any, b: any) => a + b)
                      .toString()
                  : ""}
                {row.field === "number" && "Total"}
              </td>
            </tr>
          ))}
        </thead>
        <tbody>
          {scoreRows.map((row) => (
            <tr key={row.field}>
              <td>
                <div className="name-edit">
                  {row.name}
                  {!row.isReadOnly && speechField !== row.field && (
                    <IconButton
                      size="small"
                      aria-label="Start Spreech"
                      onClick={() => startSpeech(row.field)}
                      disabled={isSpeechRunning}
                    >
                      <MicOffIcon />
                    </IconButton>
                  )}
                  {!row.isReadOnly && speechField === row.field && (
                    <IconButton size="small" aria-label="Stop Spreech" disabled={true} className="speech--active">
                      <MicOnIcon />
                    </IconButton>
                  )}
                </div>
              </td>
              {props.holeScores
                .filter((c) => holeIdMapping[c.holeId] <= 9)
                .map((h) => (
                  <td key={h.holeId}>
                    <ScoreEntry hole={h} row={row} onValueChange={onValueChange} />
                  </td>
                ))}
              <td>
                {row.showTotals
                  ? props.holeScores
                      .filter((c) => holeIdMapping[c.holeId] <= 9)
                      .map((c: any) => c[row.field])
                      .reduce(addTotals, 0)
                      .toString()
                  : ""}
              </td>
              {props.holeScores
                .filter((c) => holeIdMapping[c.holeId] >= 10)
                .map((h) => (
                  <td key={h.holeId}>
                    <ScoreEntry hole={h} row={row} onValueChange={onValueChange} />
                  </td>
                ))}
              <td>
                {row.showTotals
                  ? props.holeScores
                      .filter((c) => holeIdMapping[c.holeId] >= 10)
                      .map((c: any) => c[row.field])
                      .reduce(addTotals, 0)
                      .toString()
                  : ""}
              </td>
              <td>
                {row.showTotals
                  ? props.holeScores
                      .map((c: any) => c[row.field])
                      .reduce(addTotals, 0)
                      .toString()
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
